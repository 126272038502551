import { Component, Host, h, Prop, State, Element, Event, EventEmitter, Watch, forceUpdate } from '@stencil/core';

@Component({
  tag: 'x-toggle-button',
  styleUrl: 'x-toggle-button.sass',
})
export class XToggleButton {
  @Element() el: HTMLElement;

  @Prop({ reflect: true }) group: boolean = false;
  @Prop({ reflect: true }) exclusive: boolean = false;
  @Prop({ reflect: true, mutable: true }) selected: boolean = false;
  @Prop({ reflect: true }) stretch: boolean = false;
  @Prop() name: string;
  @Prop() value: any;
  @Prop() fieldHandleId: string;
  @Prop({ reflect: true }) size: 'sm' | 'lg';
  @Prop() multiple: boolean = false;
  @Prop({ reflect: true }) disabled: boolean = false;

  @State() innerValue: any[] = [];

  @Event() update: EventEmitter;

  @Watch('selected')
  deselectOthers(newValue) {
    if (!this.multiple) {
      const siblings = Array.prototype.slice.call(this.el.closest('x-toggle-button[group]').querySelectorAll('x-toggle-button'));

      for (const sibling of siblings) {
        if (sibling !== this.el) {
          sibling.removeAttribute('selected');
        }
      }
    }

    if (this.multiple || newValue === true) {
      this.emit();
    }
  }

  private valueInput?: HTMLInputElement;

  private emit = (params: any = {}) => {
    const detail = {
      value: this.value,
      label: this.el.innerText.trim(),
      selected: this.selected,
    };

    if (!params.initial) {
      this.update.emit(detail);
    }
  };

  private handleClick = () => {
    if (this.disabled || (this.selected && this.el.closest('x-toggle-button[group][exclusive]'))) {
      return;
    }

    this.selected = !this.selected;
  };

  private handleUpdate = e => {
    if (!this.multiple) {
      this.innerValue = [e.detail];
    } else {
      if (e.detail.selected) {
        this.innerValue.push(e.detail);
      } else {
        this.innerValue = this.innerValue.reduce((result, item) => {
          if (e.detail.label !== item.label && e.detail.value !== item.innerValue) {
            result.push(item);
          }

          return result;
        }, []);
      }
    }

    this.valueInput.value = this.innerValue.map(item => item.value).join(',');
    forceUpdate(this.el);

    setTimeout(() => {
      this.valueInput.dispatchEvent(new window.Event('change', { bubbles: true }));
    });
  };

  componentWillLoad() {
    if (this.group) {
      const options = this.el.querySelectorAll('x-toggle-button');

      Array.prototype.forEach.call(options, option => {
        option['size'] = this.size;
        option['stretch'] = this.stretch;
        option['multiple'] = this.multiple;
      });
    }
  }

  componentDidLoad() {
    setTimeout(() => {
      if (this.selected) {
        this.emit({
          initial: true,
        });
      }
    });
  }

  render() {
    if (this.group) {
      return (
        <Host onUpdate={this.handleUpdate}>
          <input
            type="hidden"
            name={this.name}
            value={this.innerValue.map(item => item.value).join(',')}
            ref={el => (this.valueInput = el as HTMLInputElement)}
            id={this.fieldHandleId}
          />
          <slot></slot>
        </Host>
      );
    }

    return (
      <Host onClick={this.handleClick}>
        <slot></slot>
      </Host>
    );
  }
}
