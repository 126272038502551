x-grid[container]
  display: flex
  flex-wrap: wrap
  min-width: 0

  &[nowrap]
    flex-wrap: nowrap

  &[direction="column"]
    flex-direction: column

  &[align="between"]
    justify-content: space-between
  &[align="right"]
    justify-content: flex-end

  &[valign="middle"]
    align-items: center
  &[valign="bottom"]
    align-items: flex-end

  @each $key, $value in $gaps
    $realValue: $value / 2
    &[row-gap="#{$key}"]
      margin-top: -#{$realValue}px
      margin-bottom: -#{$realValue}px
      > x-grid[item]
        padding-top: #{$realValue}px
        padding-bottom: #{$realValue}px
    &[column-gap="#{$key}"]
      margin-right: -#{$realValue}px
      margin-left: -#{$realValue}px
      > x-grid[item]
        padding-right: #{$realValue}px
        padding-left: #{$realValue}px
    &[gap="#{$key}"]
      margin: -#{$realValue}px
      > x-grid[item]
        padding: #{$realValue}px

x-grid[item]
  flex: 0 0 auto
  min-width: 0
  box-sizing: border-box
  &[size="auto"]
    max-width: none
    width: auto
  &[size="fill"]
    max-width: 100%
    flex: 1 1 0
