x-toggle-button:not([group])
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid rgba(255, 255, 255, 0)
  border-radius: var(--base-radius)
  cursor: pointer
  box-sizing: border-box

  &[stretch]
    width: 100%
    padding-left: 0
    padding-right: 0

  &[color="white-20"]
    color: var(--on-input-white-20)
  &[color="dark-grey"]
    color: var(--icon-dark-grey)

  &[size="sm"]
    +preset(5)
    background: var(--button-static-grey)
    border-color: var(--button-static-grey)
    color: var(--on-button-dark-50)
    height: 24px
    padding: 0 6px
    &[square]
      width: 24px
      padding: 0
    &[selected]
      background: var(--button-static-white)
      border-color: var(--on-button-active-primary)
      color: var(--on-button-active-primary)
    &:hover
      border-color: var(--button-hover-green)
      color: var(--on-button-hover-green)
  &[size="lg"]
    background: var(--button-static-white)
    border-color: var(--input-static)
    height: 40px
    &[square]
      width: 40px
    &[selected]
      border-color: var(--button-static-green)
      color: var(--primary)
    &:hover
      border-color: var(--button-hover-green)
      color: var(--on-button-hover-green)

  &[disabled]
    color: var(--button-static-dark-grey) !important
    border-color: var(--button-static-dark-grey) !important
    background: var(--button-disabled) !important
    cursor: not-allowed
