import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-grid',
  styleUrl: 'x-grid.sass',
})
export class XGrid {
  @Prop({ reflect: true }) container: boolean;
  @Prop({ reflect: true }) item: boolean;
  @Prop({ reflect: true }) size: string;
  @Prop({ reflect: true }) gap: string;
  @Prop({ reflect: true }) rowGap: string;
  @Prop({ reflect: true }) columnGap: string;
  @Prop({ reflect: true }) valign: string;
  @Prop({ reflect: true }) direction: string;
  @Prop({ reflect: true }) nowrap: boolean;

  render() {
    if (this.container) {
      return (
        <Host>
          <slot></slot>
        </Host>
      );
    } else if (this.item) {
      const style: any = {};

      if (/(%|px)$/.test(this.size)) {
        style.width = this.size;
      }

      return (
        <Host style={style}>
          <slot></slot>
        </Host>
      );
    } else {
      return null;
    }
  }
}
